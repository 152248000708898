/* FAQ Styles */
.faq {
  text-align: center;
  padding: 40px;
}

.faq h2 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 40px;
}

.faq-items {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

/* FAQ Item Styles */
.faq-item {
  margin-bottom: 20px;
  overflow: hidden;
}

.faq-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
}

.faq-item-header h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

/* Arrow Icon Styles */
.arrow {
  transition: transform 0.3s;
}

.arrow.open {
  transform: rotate(180deg);
}

/* FAQ Content Styles */
.faq-item-content {
  padding: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}

.faq-item-content.open {
  max-height: 1000px; /* Adjust as needed */
}

.faq-item-content p {
  font-size: 18px;
  line-height: 1.6;
}