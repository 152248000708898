.story-chapters {
    padding: 40px;
    background-color: #000000;
    color: #eeeeee;
    text-align: center;
  }
  
  .story-chapters h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .chapters-container {
    display: flex;
  }
  
  .chapter-list {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }
  
  .chapter-button {
    background-color: #333333;
    border: none;
    color: #eeeeee;
    padding: 10px 20px;
    margin: 5px 0;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    text-align: left;
  }
  
  .chapter-button:hover {
    background-color: #444444;
  }
  
  .chapter-button.active {
    background-color: #b8dd97;
    color: #000000;
  }
  
  .chapter-button:disabled {
    background-color: #555555;
    color: #999999;
    cursor: not-allowed;
  }
  
  .chapter-content {
    flex: 1;
    margin-left: 20px;
    padding: 20px;
    background-color: #1a1a1a;
    border-radius: 4px;
    text-align: left;
    min-height: 200px;
    position: relative;
  }
  
  .chapter-content p {
    font-size: 18px;
    line-height: 1.6;
  }
  
  .summary-container {
    display: flex;
    align-items: center;
  }
  
  .summary-content {
    flex: 1;
    text-align: left;
  }
  
  .summary-content p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #c7c7c7;
  }
  
  .summary-image {
    flex: 1;
    height: 400px;
    border-radius: 20px;
    margin-left: 40px;
  }
  
  .view-story {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: #b8dd97;
    border: none;
    color: #000000;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    transition: background-color 0.3s;
  }
  
  .view-story:hover {
    background-color: #9dbd81;
  }