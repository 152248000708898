.mint-transfer-page {
    padding: 40px;
    background-color: #000000;
    color: #eeeeee;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .mint-section {
    background-color: #1a1a1a;
    padding: 120px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    max-width: 400px; /* Adjusted for a more focused form */
  }
  
  .mint-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Added gap for spacing between inputs and button */
  }
  
  .mint-input-container input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    margin: 10px 0; /* Added margin for spacing */
    width: 100%; /* Full width to match the container's width */
    max-width: 300px; /* Limit input width for better aesthetics */
    color: #000; /* Text color for better readability */
    background-color: #eeeeee; /* Background color for better visibility */
  }
  
  .mint-input-container button {
    background-color: #eeeeee;
    color: #000000;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    max-width: 200px; /* Adjusted width for a more appealing button */
  }
  
  /* Additional styles for visual feedback during processing */
  .mint-input-container button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
  