.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    min-width: 300px;
    min-height: 300px;
    padding: 200px;
    color: black;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content button {
    margin-top: 10px;
    float: right;
  }
  