.goal {
    text-align: center;
    padding: 40px;
    background-color: #000000;
  }
  
  .goal h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #eeeeee;
  }
  
  .goal p {
    font-size: 18px;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    color: #c7c7c7;
  }