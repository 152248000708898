/* App.css */
.app {
  background-color: #000000;
  color: #eeeeee;
  font-family: 'Roboto', sans-serif;
  height: 100vh;
}
body{
  background-color: black;
}

/* Header.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 40px;
  background-color: #000000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 80px;
}

nav ul {
  display: flex;
  list-style: none;
  align-items: center;
}

nav li {
  margin-right: 30px;
}

nav a {
  color: #eaeaea;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.3s;
}

nav a:hover {
  color: #b8dd97;
}

.connect-btn {
  background-color: #b8dd97;
  border: none;
  color: #000000;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  transition: background-color 0.3s;
}

.connect-btn:hover {
  background-color: #9dbd81;
}


/* Hero.css */
.hero {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden; /* This will ensure the gradient is clipped correctly */
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity (last value) to make the overlay darker or lighter */
  z-index: -1; /* This will ensure the overlay is behind the content */
}

.hero::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30vh; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%); /* Adjust the colors and opacity as needed */
  z-index: 1; /* This will ensure the gradient is on top of the background image */
}

.hero-content {
  text-align: left;
  width: 40%;
  margin-bottom: 100px;
}

.hero h1 {
  font-size: 2rem; /* Adjust the font size as needed */
  margin-bottom: 2rem;
}

.scroll-indicator {
  animation: bounce 2s infinite;
}

.arrow-icon {
  font-size: 2rem; /* Adjust the icon size as needed */
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.cta-button {
  background-color: #b8dd97;
  border: none;
  color: #000000;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #9dbd81;
}

.hero-image {
  flex: 1;
  max-width: 50%;
  text-align: right;
}

.hero-image img {
  max-width: 80%;
  height: auto;
  border-radius: 20px;
}


/* About.css */
.about {
  padding: 80px 40px;
  margin: 100px;
  border-radius: 20px;
  background-color: #000000;
  text-align: center;
}

.about h2 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #f5f5f5;
}

.about p {
  font-size: 18px;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
  color: #c7c7c7;
}

/* Roadmap.css */
.roadmap {
  padding: 80px 40px;
  background-color: #fff;
  text-align: center;
}

.roadmap h2 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 40px;
  color: #333;
}

.roadmap ul {
  list-style: none;
  font-size: 18px;
  max-width: 600px;
  margin: 0 auto;
}

.roadmap li {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #555;
}

/* Team.css */
.team {
  padding: 80px 40px;
  background-color: #f8f9fa;
  text-align: center;
}

.team h2 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.team p {
  font-size: 18px;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
  color: #555;
}

/* Story.css */
.story {
  padding: 80px 40px;
  background-color: #fff;
  text-align: center;
}

.story h2 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.story p {
  font-size: 18px;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto 40px;
  color: #555;
}

.story ul {
  list-style: none;
  font-size: 18px;
  max-width: 400px;
  margin: 0 auto;
  text-align: left;
}

.story li {
  margin-bottom: 10px;
  color: #555;
}

.story button {
  background-color: #b8dd97;
  border: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
}

/* Footer.css */
/* Footer Styles */
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #000000; /* Match your site's footer color */
  color: #ffffff;
}

.footer-left p {
  margin: 0; /* Adjust as necessary */
}

.footer-right {
  display: flex;
  align-items: center;
}

.footer-right a {
  color: #eeeeee; /* Light text color for links */
  text-decoration: none;
  margin-left: 20px; /* Space between links */
  font-size: 16px;
  transition: color 0.3s ease;
}

.footer-right a:hover {
  color: #b8dd97; /* Highlight color on hover */
}


.image-slider {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 100px;
}

.slide {
  display: flex;
  animation: slideLeft 30s linear infinite; /* Adjust the animation duration as needed */
}

.slide img {
  flex-shrink: 0;
  width: calc(20% - 16px); /* Adjust the width of the images as needed, and subtract the desired spacing */
  height: auto;
  margin-right: 20px; /* Add spacing between images */
  border-radius: 20px;
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 20px)); /* Move the slide to the left by 100% of its width plus the spacing */
  }
}