.image-slider {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 10px;
  }
  
  .slide {
    display: flex;
    animation: slideRight 30s linear infinite; /* Adjust the animation duration as needed */
  }
  
  .slide-right {
    animation-direction: reverse; /* Reverse the animation direction */
  }
  
  .slide img {
    flex-shrink: 0;
    width: calc(20% - 16px); /* Adjust the width of the images as needed, and subtract the desired spacing */
    height: auto;
    margin-right: 20px; /* Add spacing between images */
    border-radius: 20px;
  }
  
  @keyframes slideRight {
    0% {
      transform: translateX(calc(-100% - 20px)); /* Start from the left side */
    }
    100% {
      transform: translateX(0); /* Move the slide to the right */
    }
  }