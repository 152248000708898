/* MintModal.css */
.mint-container {
    color: #eeeeee; /* Light text color */
    padding: 20px;
    min-width: 500px;
    min-height: 300px;
    border-radius: 8px;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8.5px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%; /* Responsive width */
    margin: auto; /* Center the modal */
  }

  .modal-content{
    background-color: #000000;
    color: white;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mint-section{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  
  .mint-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
   
  }
  
  .mint-controls button, .mint-button {
    background-color: #b8dd97; /* Green background for buttons */
    border: none;
    color: #000000; /* Dark text for contrast */
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin: 0 10px; /* Space between buttons */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .mint-controls button:hover, .mint-button:hover {
    background-color: #9dbd81; /* Darker shade of green for hover effect */
    
  }
  
  .mint-button {
    background-color: #b8dd97;
    border: none;
    color: #000000;
    padding: 15px 100px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
  }
  
  /* Additional styles for MintModal if needed */
  