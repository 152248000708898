.story-summary {
    padding: 40px;
    background-color: #00000000;
    color: #eeeeee;
    text-align: center;
    
  }
  
  .story-summary h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .summary-container {
    display: flex;
    align-items: center;
  }
  
  .summary-content {
    flex: 1;
    text-align: left;
  }
  
  .summary-content p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #c7c7c7;
  }
  
  .summary-image {
    flex: 1;
    height: 400px;
    border-radius: 20px;
    margin-left: 40px;
  }
  
  .connect-wallet {
    background-color: #b8dd97;
    border: none;
    color: #000000;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    transition: background-color 0.3s;
  }
  
  .connect-wallet:hover {
    background-color: #9dbd81;
  }