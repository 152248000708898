.story-intro {
    text-align: center;
    padding: 40px;
    position: relative;
    background-color: #b8dd97;
    margin: 50px;
    border-radius: 20px;
  }
  
  .story-intro h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #1a1a1a;
  }
  
  .story-intro p {
    font-size: 18px;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto 40px;
    color: #262626;
  }
  
  .intro-image {
    height: 300px;
    width: 100%;
    border-radius: 20px;
  }


  .view-story2 {
    margin-top: 30px;
    background-color: #000000;
    border: none;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    transition: background-color 0.3s;
  }
  
  .view-story:hover {
    background-color: #9dbd81;
  }